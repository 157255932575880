import { DynamicKpiData } from 'types/subscription';
import { GENERAL_BFF_PREFIX } from 'constants/general';
import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from 'hooks/useApi';
import useBusinessId from './useBusinessId';

type UseKpiProps = {
    businessId?: string;
    year: string;
    month?: string;
    week?: string;
};
type UseKpiResult = {
    kpiData?: DynamicKpiData;
    isLoading: boolean;
    error?: ApiError;
};

const useKpi = ({ businessId, year, month, week }: UseKpiProps): UseKpiResult => {
    const paramsArray: string[][] = [];
    const { activeParentBusinessId } = useBusinessId();
    paramsArray.push(['year', year]);

    if (month) {
        paramsArray.push(['month', month]);
    } else if (week) {
        paramsArray.push(['week', week]);
    }
    if (businessId) {
        paramsArray.push(['businessId', businessId]);
    }

    const { get } = useApi<DynamicKpiData>();
    const urlParams = new URLSearchParams(paramsArray).toString();
    const url = `${GENERAL_BFF_PREFIX}/v1/businesses/${activeParentBusinessId}/billing-transactions/key-performance-indicators?${urlParams}`;
    const fetcher: () => Promise<DynamicKpiData> = () => get(url);

    const {
        data: kpiData,
        isFetching: isLoading,
        error,
    } = useQuery<DynamicKpiData, ApiError>({
        queryKey: [`${url}?${businessId}`],
        queryFn: fetcher,
        enabled: !!year && !!activeParentBusinessId,
    });

    return {
        kpiData,
        isLoading,
        error: error ?? undefined,
    };
};

export default useKpi;
