import { ConsumedEnergy } from 'types/subscription';
import { generatePartnerRoamingData, getTotalKwh } from 'utils/subscription/chart';
import { purple } from 'styles/variables';
import { useNumber } from 'hooks/useNumber';
import { useTranslation } from 'react-i18next';
import KpiContainer from 'components/kpi/KpiContainer';
import KpiFooterText from 'components/kpi/KpiFooterText';
import PieChart from 'components/charts/PieChart';

type PartnerRoamingKpiProps = {
    data: ConsumedEnergy[];
};

function PartnerRoamingKpi({ data }: PartnerRoamingKpiProps): JSX.Element {
    const { t } = useTranslation();
    const chartData = generatePartnerRoamingData(data);
    const totalKwh = getTotalKwh(data).toFixed(2);
    const { formatNumber } = useNumber();
    return (
        <KpiContainer
            title={t('subscription.statistics.kpi.partnerRoaming')}
            content={<PieChart chartData={chartData} unit="kWh" color={purple} noData={!chartData.length} />}
            footerItems={
                <KpiFooterText
                    rowOne={t('subscription.statistics.kpi.totalKwh')}
                    rowTwo={formatNumber(Number(totalKwh))}
                />
            }
            dataTestId="partnerRoamingKpi"
        />
    );
}

export default PartnerRoamingKpi;
