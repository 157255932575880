import { EBusinessFeature } from 'types/business';
import { EPageErrorType, FEATURE_LIST } from 'types';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PATH } from 'constants/paths';
import { showFeature } from 'utils/featureFlag';
import AuthRoute from 'components/AuthRoute';
import CreateSubscriptionPage from 'pages/subscription/CreateSubscriptionPage';
import ErrorPage from 'pages/ErrorPage';
import FacilityManagementPage from 'pages/facility/FacilityManagementPage';
import FacilityStatisticsPage from 'pages/facility/FacilityStatisticsPage';
import InvoicesPage from 'pages/invoices/InvoicesPage';
import MyAccountPage from 'pages/MyAccountPage';
import PageLayout from 'components/layout/PageLayout';
import PageLayoutWithoutMenu from 'components/layout/PageLayoutWithoutMenu';
import RouteWrapper from 'components/wrappers/RouteWrapper';
import SignUpPage from 'pages/SignUpPage';
import Startpage from 'pages/Startpage';
import SubscriptionImportPage from 'pages/subscription/SubscriptionImportPage';
import SubscriptionOverviewPage from 'pages/subscription/SubscriptionOverviewPage';
import SubscriptionStatisticsPage from 'pages/subscription/SubscriptionStatisticsPage';

function AppRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path={PATH.SIGNUP} element={<SignUpPage />} />
            <Route element={<RouteWrapper />}>
                <Route
                    path={PATH.STARTPAGE}
                    element={
                        <PageLayout>
                            <AuthRoute feature={Object.values(EBusinessFeature)}>
                                <Startpage />
                            </AuthRoute>
                        </PageLayout>
                    }
                />
                <Route
                    path={PATH.SUBSCRIPTION_CREATE}
                    element={
                        <PageLayout>
                            <AuthRoute feature={[EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT]}>
                                <CreateSubscriptionPage />
                            </AuthRoute>
                        </PageLayout>
                    }
                />
                <Route
                    path={PATH.SUBSCRIPTION_IMPORT}
                    element={
                        <PageLayout>
                            <AuthRoute feature={[EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT]}>
                                <SubscriptionImportPage />
                            </AuthRoute>
                        </PageLayout>
                    }
                />
                <Route
                    path={PATH.SUBSCRIPTION_STATISTICS}
                    element={
                        <PageLayout>
                            <AuthRoute
                                feature={[
                                    EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT,
                                    EBusinessFeature.SUBSCRIPTION_MANAGEMENT_VIEW,
                                ]}
                            >
                                <SubscriptionStatisticsPage />
                            </AuthRoute>
                        </PageLayout>
                    }
                />
                <Route path={PATH.SUBSCRIPTION_OVERVIEW}>
                    <Route
                        index
                        element={
                            <PageLayout>
                                <AuthRoute
                                    feature={[
                                        EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT,
                                        EBusinessFeature.SUBSCRIPTION_MANAGEMENT_VIEW,
                                    ]}
                                >
                                    <SubscriptionOverviewPage />
                                </AuthRoute>
                            </PageLayout>
                        }
                    />
                </Route>
                {showFeature(FEATURE_LIST.INVOICES) && (
                    <Route
                        path={PATH.INVOICES}
                        element={
                            <PageLayout>
                                <AuthRoute feature={[EBusinessFeature.INVOICES_MANAGEMENT_VIEW]}>
                                    <InvoicesPage />
                                </AuthRoute>
                            </PageLayout>
                        }
                    />
                )}
                <Route
                    path={PATH.MY_ACCOUNT}
                    element={
                        <PageLayout>
                            <MyAccountPage />
                        </PageLayout>
                    }
                />
                <Route
                    path={PATH.WILDCARD}
                    element={
                        <PageLayout>
                            <ErrorPage type={EPageErrorType.BROKEN_URL} />
                        </PageLayout>
                    }
                />
                <Route
                    path={PATH.ERROR}
                    element={
                        <PageLayout>
                            <ErrorPage type={EPageErrorType.TEMPORARY} />
                        </PageLayout>
                    }
                />
                <Route
                    path={PATH.CRITICAL_ERROR_NO_MENU}
                    element={
                        <PageLayoutWithoutMenu>
                            <ErrorPage type={EPageErrorType.CRITICAL_ERROR_NO_MENU} />
                        </PageLayoutWithoutMenu>
                    }
                />
                <Route
                    path={PATH.TEMPORARY_ERROR_NO_MENU}
                    element={
                        <PageLayoutWithoutMenu>
                            <ErrorPage type={EPageErrorType.TEMPORARY_ERROR_NO_MENU} home refresh />
                        </PageLayoutWithoutMenu>
                    }
                />
                <Route
                    path={PATH.NO_PERMISSION}
                    element={
                        <PageLayout>
                            <ErrorPage type={EPageErrorType.NO_PERMISSION} />
                        </PageLayout>
                    }
                />
                <Route
                    path={PATH.FACILITY_STATISTICS}
                    element={
                        <PageLayout>
                            <AuthRoute
                                feature={[
                                    EBusinessFeature.FACILITY_MANAGEMENT_EDIT,
                                    EBusinessFeature.FACILITY_MANAGEMENT_VIEW,
                                ]}
                            >
                                <FacilityStatisticsPage />
                            </AuthRoute>
                        </PageLayout>
                    }
                />
                {showFeature(FEATURE_LIST.FACILITY_MANAGEMENT) && (
                    <Route
                        path={PATH.FACILITY_MANAGEMENT}
                        element={
                            <PageLayout>
                                <AuthRoute
                                    feature={[
                                        EBusinessFeature.FACILITY_MANAGEMENT_EDIT,
                                        EBusinessFeature.FACILITY_MANAGEMENT_VIEW,
                                    ]}
                                >
                                    <FacilityManagementPage />
                                </AuthRoute>
                            </PageLayout>
                        }
                    />
                )}
                {/*  DEPRECATED ROUTES - revisit and remove when not used anympore (Check GA) */}
                <Route
                    path={PATH.DEPRECATED_SUBSCRIPTION_OVERVIEW}
                    element={<Navigate to={PATH.SUBSCRIPTION_OVERVIEW} replace />}
                />
                <Route
                    path={PATH.DEPRECATED_SUBSCRIPTION_CREATE}
                    element={<Navigate to={PATH.SUBSCRIPTION_CREATE} replace />}
                />
                <Route
                    path={PATH.DEPRECATED_SUBSCRIPTION_IMPORT}
                    element={<Navigate to={PATH.SUBSCRIPTION_IMPORT} replace />}
                />
                <Route
                    path={PATH.DEPRECATED_SUBSCRIPTION_STATISTICS}
                    element={<Navigate to={PATH.SUBSCRIPTION_STATISTICS} replace />}
                />
                <Route
                    path={PATH.DEPRECATED_FACILITY_STATISTICS}
                    element={<Navigate to={PATH.FACILITY_STATISTICS} replace />}
                />
            </Route>
        </Routes>
    );
}

export default AppRoutes;
