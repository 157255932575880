import { Address } from 'types';

export type Organisation = {
    id: string;
    name: string;
    address?: Address;
    countryCode?: string;
};
export interface Business extends Organisation {
    subsidiaries?: Subsidiary[];
    features?: EBusinessFeature[];
    hasFacilityManagementInAllBusinesses: boolean;
}

export interface Subsidiary extends Organisation {
    features?: EBusinessFeature[];
}

export enum EBusinessFeature {
    SUBSCRIPTION_MANAGEMENT_EDIT = 'SUBSCRIPTION_MANAGEMENT_EDIT',
    SUBSCRIPTION_MANAGEMENT_VIEW = 'SUBSCRIPTION_MANAGEMENT_VIEW',
    FACILITY_MANAGEMENT_EDIT = 'FACILITY_MANAGEMENT_EDIT',
    FACILITY_MANAGEMENT_VIEW = 'FACILITY_MANAGEMENT_VIEW',
    INVOICES_MANAGEMENT_VIEW = 'INVOICES_MANAGEMENT_VIEW',
}
