import { generateActiveSubscriptions, getActiveSubscriptionsTotalNumber } from 'utils/subscription/chart';
import { useContext, useEffect, useState } from 'react';
import { useNumber } from 'hooks/useNumber';
import { useTranslation } from 'react-i18next';
import KpiContainer from 'components/kpi/KpiContainer';
import KpiContent from 'components/kpi/KpiContent';
import KpiListItem, { KpiList } from 'components/kpi/KpiListItem';
import PopUp from 'components/info/PopUp';
import SmallThunder from 'assets/icons/small_thunder.svg?react';
import SubscriptionStatisticsContext from 'contexts/SubscriptionStatisticsContext';
import TooltipContentComponent from 'components/info/TooltipContent';
import Warning from 'assets/icons/warning.svg?react';
import WarningIcon from 'assets/icons/ic_warning_solid.svg?react';
import useActiveSubscriptions from 'hooks/useActiveSubscriptions';

function ActiveSubscriptionsKpi(): JSX.Element {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    const organisationId = useContext(SubscriptionStatisticsContext);
    const isAllSelected = !!organisationId.includes('all');
    const [hasError, setHasError] = useState(false);

    const {
        activeSubscriptions = [],
        error: errorActiveSubscriptions,
        isLoading: isLoadingActiveSubscriptions,
    } = useActiveSubscriptions({
        businessId: !isAllSelected ? organisationId : undefined,
    });

    useEffect(() => {
        if (!isLoadingActiveSubscriptions && errorActiveSubscriptions) {
            setHasError(true);
        }
    }, [setHasError, errorActiveSubscriptions, isLoadingActiveSubscriptions]);

    const chartData = generateActiveSubscriptions(activeSubscriptions);
    const total = getActiveSubscriptionsTotalNumber(activeSubscriptions);

    return (
        <KpiContainer
            title={t('subscription.statistics.kpi.activeSubscriptions.title')}
            subtitle={t('subscription.statistics.kpi.activeSubscriptions.subtitle')}
            dataTestId="activeSubscriptionsKpi"
            tooltip={
                hasError && (
                    <PopUp
                        icon={<WarningIcon />}
                        dataTestId="activeSubscriptionsKpiError"
                        content={
                            <TooltipContentComponent
                                headline={t('subscription.statistics.kpi.error.headline')}
                                text={t('subscription.statistics.kpi.error.text')}
                                icon={<Warning />}
                            />
                        }
                    />
                )
            }
            content={
                <>
                    <KpiContent icon={<SmallThunder />} text={!hasError ? formatNumber(total) : '-'} />
                    <KpiList>
                        {chartData.map((subscription, idx) => {
                            return (
                                <KpiListItem
                                    key={subscription.name}
                                    dataTestId={`subscription_${idx}`}
                                    title={subscription.name}
                                    value={!hasError ? formatNumber(Number(subscription.value)) : '-'}
                                />
                            );
                        })}
                    </KpiList>
                </>
            }
        />
    );
}

export default ActiveSubscriptionsKpi;
