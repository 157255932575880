import { ELocale } from 'types';
import { useCountrySpecificContent } from './useCountrySpecificContent';
import { useTranslation } from 'react-i18next';

type UseNumberReturnPorps = {
    formatDate: (date?: string | Date, shouldDisplayTime?: boolean) => string;
    autoAddSeparator: (dateString: string) => string;
    shouldRemoveSeparator: (dateString: string) => boolean;
};

export const useDate = (): UseNumberReturnPorps => {
    const { locale } = useCountrySpecificContent();
    const { t } = useTranslation();

    const formatDate = (date?: string | Date, shouldDisplayTime = true): string => {
        if (date) {
            try {
                const newDate = new Date(date);
                const localeDate = new Intl.DateTimeFormat(locale, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    ...(shouldDisplayTime && { hour: 'numeric', minute: 'numeric' }),
                }).format(newDate);
                return localeDate;
            } catch (error) {
                if (error instanceof RangeError) {
                    return t('general.invalidDate');
                }
            }
        }
        return '';
    };

    const autoAddSeparator = (dateString: string): string => {
        let date = dateString;
        // Sweden
        if (locale === ELocale.SV && (date.length === 4 || date.length === 7)) {
            date += '-';
        }
        // Germany
        if (locale === ELocale.DE && (date.length === 2 || date.length === 5)) {
            date += '.';
        }
        return date;
    };

    const shouldRemoveSeparator = (dateString: string): boolean => {
        if (locale === ELocale.DE) {
            return dateString.length === 3 || dateString.length === 6;
        }

        return dateString.length === 5 || dateString.length === 8;
    };

    return { formatDate, autoAddSeparator, shouldRemoveSeparator };
};
