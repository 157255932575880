import { Business, EBusinessFeature } from 'types/business';
import { DropDownType } from 'types';
import { t } from 'i18next';
import useBusinessId from './useBusinessId';

type useBusinessResult = {
    getActiveBusiness: () => Business | undefined;
    getBusiness: (businessId: string) => Business | undefined;
    getBusinessByName: (businessName: string) => Business | undefined;
    getBusinessesListByFeature: (features: EBusinessFeature[], addAllItem?: boolean) => DropDownType[];
};

const useGetBusiness = (businesses: Business[]): useBusinessResult => {
    const { activeParentBusinessId } = useBusinessId();

    const getActiveBusiness = (): Business | undefined =>
        businesses.find((business) => business.id === activeParentBusinessId);

    const getBusiness = (businessId: string): Business | undefined => {
        let businessToReturn;
        businesses.forEach((business) => {
            if (business.id === businessId) {
                businessToReturn = business;
            } else {
                business?.subsidiaries?.forEach((sub) => {
                    if (sub.id === businessId) {
                        businessToReturn = sub;
                    }
                });
            }
        });

        return businessToReturn;
    };

    const getBusinessByName = (businessName: string): Business | undefined => {
        let businessToReturn;
        businesses.forEach((business) => {
            if (business.name === businessName) {
                businessToReturn = business;
            } else {
                business?.subsidiaries?.forEach((sub) => {
                    if (sub.name === businessName) {
                        businessToReturn = sub;
                    }
                });
            }
        });

        return businessToReturn;
    };

    const getBusinessesListByFeature = (features: EBusinessFeature[], addAllItem?: boolean): DropDownType[] => {
        const business = getActiveBusiness();
        const businessList = [];
        if (business && features.some((f) => business.features?.includes(f))) {
            businessList.push({
                value: business.id,
                text: business.name,
            });
        }

        business?.subsidiaries?.forEach((sub) => {
            if (features.some((f) => sub.features?.includes(f))) {
                businessList.push({
                    value: sub.id,
                    text: sub.name,
                });
            }
        });

        if (addAllItem && businessList.length > 1) {
            return [
                { value: `${business?.id}_all`, text: t('form.input.organisation.allOrganisationsOption') },
                ...businessList,
            ];
        }

        return businessList;
    };

    return {
        getActiveBusiness,
        getBusiness,
        getBusinessByName,
        getBusinessesListByFeature,
    };
};

export default useGetBusiness;
