export type useBusinessIdResult = {
    activeParentBusinessId: string;
};

const useBusinessId = (): useBusinessIdResult => {
    const activeParentBusinessId = localStorage.getItem('activeParentBusinessId') ?? '';
    return {
        activeParentBusinessId,
    };
};

export default useBusinessId;
