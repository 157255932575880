import { ConsumedEnergy } from 'types/subscription';
import { darkPink } from 'styles/variables';
import { generateTotalKwhData, getTotalKwh } from 'utils/subscription/chart';
import { useNumber } from 'hooks/useNumber';
import { useTranslation } from 'react-i18next';
import KpiContainer from 'components/kpi/KpiContainer';
import KpiFooterText from 'components/kpi/KpiFooterText';
import PieChart from 'components/charts/PieChart';

type TotalKwhKpiProps = {
    data: ConsumedEnergy[];
};

function TotalKwhKpi({ data }: TotalKwhKpiProps): JSX.Element {
    const { t } = useTranslation();
    const chartData = generateTotalKwhData(data);
    const totalKwh = getTotalKwh(data).toFixed(2);
    const { formatNumber } = useNumber();

    return (
        <KpiContainer
            title={t('subscription.statistics.kpi.totalKwh')}
            content={<PieChart chartData={chartData} unit="kWh" color={darkPink} noData={chartData.length <= 0} />}
            footerItems={
                <KpiFooterText
                    rowOne={t('subscription.statistics.kpi.totalKwh')}
                    rowTwo={formatNumber(Number(totalKwh))}
                />
            }
            dataTestId="totalKwhKpi"
        />
    );
}

export default TotalKwhKpi;
