import { useCountrySpecificContent } from './useCountrySpecificContent';

type UseNumberReturnPorps = {
    formatNumber: (number: number) => string;
};

export const useNumber = (): UseNumberReturnPorps => {
    const { locale } = useCountrySpecificContent();

    const formatNumber = (number: number): string => {
        return new Intl.NumberFormat(locale).format(number);
    };

    return { formatNumber };
};
