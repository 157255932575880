import { ELocale } from 'types';
import { InteractionStatus } from '@azure/msal-browser';
import { PATH } from 'constants/paths';
import { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useLanguage } from 'hooks/useLanguage';
import { useLocation } from 'react-router-dom';
import ErrorPageWithoutUserMenu from 'pages/ErrorPageWithoutUserMenu';
import FullPageLoadingIndicator from 'components/indicators/FullPageLoadingIndicator';
import RouteContentWrapper from './RouteContentWrapper';
import SignUpPage from 'pages/SignUpPage';
import i18n from 'i18n';
import useAuthentication from 'hooks/useAuthentication';

const IS_LOADING_CLASS = 'is-loading';

function AppRoutesWrapper(): JSX.Element {
    const { inProgress, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { pathname } = useLocation();

    const { getValidBULanguage } = useLanguage();

    const { existsAccount, loginRedirectRegular, registerLogoutCallback, logoutRedirectRegular, getAccount } =
        useAuthentication();

    const [error, setError] = useState('');

    useEffect(() => {
        if (localStorage.getItem('locale') !== i18n.language) {
            i18n.changeLanguage(getValidBULanguage(i18n.language as ELocale));
        }
        if (!isAuthenticated && inProgress === InteractionStatus.None && !existsAccount() && pathname !== PATH.SIGNUP) {
            try {
                if (!error) {
                    loginRedirectRegular();
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('Something went wrong in AppRoutesWrapper when NONE is inProgress: ', e);
            }
        } else if (existsAccount() && isAuthenticated && pathname === PATH.SIGNUP) {
            try {
                logoutRedirectRegular(false);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('Something went wrong in AppRoutesWrapper during logout 1: ', e);
            }
        } else if (
            isAuthenticated &&
            inProgress === InteractionStatus.None &&
            import.meta.env.VITE_APP_AZUREAD_AUTHORITY_CHANGEEMAIL?.toLocaleLowerCase().includes(
                getAccount()?.tenantId || '',
            )
        ) {
            try {
                logoutRedirectRegular(false);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('Something went wrong in AppRoutesWrapper during logout 2: ', e);
            }
        } else if (inProgress === InteractionStatus.HandleRedirect) {
            instance
                .handleRedirectPromise()
                .then((redirectResponse) => {
                    if (!redirectResponse) {
                        return;
                    }
                    const responseAuthority = redirectResponse.authority.toLowerCase();
                    if (
                        responseAuthority.startsWith(
                            import.meta.env.VITE_APP_AZUREAD_AUTHORITY_CHANGEEMAIL?.toLocaleLowerCase() || '',
                        )
                    ) {
                        logoutRedirectRegular(false);
                    }
                })
                .catch((e) => {
                    setError(e);
                    // eslint-disable-next-line no-console
                    return console.error('Something went wrong in AppRoutesWrapper: ', e);
                });
        }
        registerLogoutCallback();
    }, [
        error,
        isAuthenticated,
        inProgress,
        existsAccount,
        loginRedirectRegular,
        registerLogoutCallback,
        pathname,
        logoutRedirectRegular,
        instance,
        getAccount,
        getValidBULanguage,
    ]);

    if (pathname === PATH.SIGNUP) return <SignUpPage />;

    if (isAuthenticated && inProgress === InteractionStatus.None) {
        return <RouteContentWrapper />;
    }
    document.documentElement.classList.add(IS_LOADING_CLASS);

    if (error) {
        return <ErrorPageWithoutUserMenu />;
    }

    return <FullPageLoadingIndicator />;
}

export default AppRoutesWrapper;
